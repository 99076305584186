import { graphql, useStaticQuery } from 'gatsby';
import { useSelector } from 'react-redux';
import Fuse from 'fuse.js';
import deNodify from '../lib/deNodify';
import { findImage, findInteriorStandardShopifyProduct } from '../lib/product';

type VariantData = {
  availableForSale: boolean;
  id: string;
  inventoryPolicy: string;
  price: number;
  shopifyId: string;
  title: string;
};

type PaintData = {
  handle: string;
  id: string;
  products: ProductData[];
  shopifyId: string;
  title: string;
};

type ProductData = {
  description: string;
  descriptionHtml: string;
  featuredMedia: {
    preview: {
      image: {
        src: string;
      };
    };
  };
  handle: string;
  priceRangeV2: {
    maxVariantPrice: {
      amount: number;
    };
    minVariantPrice: {
      amount: number;
    };
  };
  designer: { value: string | null };
  tags: string[];
  title: string;
  productType: string;
  collections: {
    title: string;
    handle: string;
    collectionType: { value: string };
  }[];
};

type SearchResult = {
  products: ProductData[];
  title: string;
  handle: string;
  id: string;
  imageUrl: string;
  type: 'paint' | 'product';
};

export function useGlobalSearhResults() {
  const data = useStaticQuery(graphql`
    fragment SearchResultProductFields on ShopifyProduct {
      title
      media {
        preview {
          image {
            originalSrc
          }
        }
      }
      handle
      productType
      description
      descriptionHtml
      collections {
        title
        handle
        collectionType: metafield(namespace: "collection", key: "type") {
          value
        }
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
        }
        minVariantPrice {
          amount
        }
      }
      designer: metafield(key: "name", namespace: "designer") {
        value
      }
      tags
    }

    query GlobalSearch {
      paint: allShopifyCollection(
        filter: {
          metafields: {
            elemMatch: {
              key: { eq: "type" }
              namespace: { eq: "collection" }
              value: { eq: "Paint" }
            }
          }
        }
        sort: [
          { products: { variants: { product: { handle: ASC } } } }
          { products: { variants: { id: ASC } } }
        ]
      ) {
        edges {
          node {
            id
            shopifyId
            title
            handle
            metafields {
              type
              key
              value
              description
              namespace
            }
            products {
              ...SearchResultProductFields
            }
          }
        }
      }
      products: allShopifyProduct(
        filter: {
          productType: {
            nin: [
              "Interior Standard"
              "Interior Semi-Gloss"
              "Exterior Standard"
              "Exterior Semi-Gloss"
              "Cabinet & Door"
            ]
          }
        }
      ) {
        edges {
          node {
            ...SearchResultProductFields
          }
        }
      }
    }
  `);

  const paint: PaintData[] = deNodify(data.paint);
  const products: ProductData[] = deNodify(data.products);

  const paintItems: SearchResult[] = paint.map((p) => {
    const collectionNames = p.products
      .map((prod) =>
        prod.collections
          .filter((c) =>
            ['Default', 'Paint Collection'].includes(c.collectionType?.value)
          )
          .map((c) => c.title)
      )
      .flat();

    return {
      title: p.title,
      handle: findInteriorStandardShopifyProduct(p.products).handle,
      id: p.id,
      type: 'paint',
      products: p.products,
      collections: collectionNames,
      designer: null,
      imageUrl: findImage(
        p.products[0].media.map((m) => m.preview.image),
        '_grid'
      ),
    };
  });

  const productItems: SearchResult[] = products.map((product) => {
    const collectionNames = product.collections
      .filter((c) =>
        ['Default', 'Wallcoverings Collection'].includes(
          c.collectionType?.value
        )
      )
      .map((c) => c.title);

  const imageUrl = product.productType === 'Rugs' 
    ? findImage(product.media.map((m) => m.preview.image), '_hero')
    : findImage(product.media.map((m) => m.preview.image), '_grid')
    
    return {
      title: product.title,
      handle: product.handle,
      id: product.handle,
      type: 'product',
      products: [product],
      collections: collectionNames,
      designer: product.designer?.value,
      imageUrl,
    };
  });

  const searchQuery = useSelector((state) => state.ui.globalSearchQuery);

  const searchedAttributes = [
    'title',
    'description',
    'tags',
    'collections',
    'designer',
  ];

  const fuse = new Fuse([...paintItems, ...productItems], {
    includeScore: true,
    keys: searchedAttributes,
    minMatchCharLength: 3,
    threshold: 0.3,
    isCaseSensitive: false,
  });

  const results = fuse.search(searchQuery);

  return { searchQuery, results, searchedAttributes };
}
