import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setOpen } from '../state/cart/cartSlice';
import { clearPlacementDetails } from '../state/gallery/gallerySlice';
import {
  setActiveHeaderSubMenu,
  setGlobalSearchQuery,
  setMainMenuActive,
} from '../state/ui/uiSlice';

function useLocationUi(location) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setOpen(false));
    dispatch(clearPlacementDetails());
    dispatch(setMainMenuActive(false));
    dispatch(setActiveHeaderSubMenu(null));
    dispatch(setGlobalSearchQuery(''));
  }, [location]);
}

export default useLocationUi;
