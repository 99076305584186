import React, { use, useRef } from 'react';
import { Box, TextInput } from 'grommet';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { setGlobalSearchQuery } from '../../state/ui/uiSlice';
import IconSearch from '../Icons/IconSearch';

const SearchInput = styled(TextInput)`
  border: none;
  border-bottom: 1px solid black;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  max-width: 400px;
  background: transparent;
  color: black;
  height: auto;

  &:focus {
    outline: none;
  }

  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (max-width: ${p.theme.global.breakpoints.small.value}px)`} {
    padding: 0;
    border-bottom: none;

    &::placeholder {
      color: transparent;
    }
    &:focus {
      &::placeholder {
        color: ${(p) => p.theme.global.colors['placeholder']};
      }
    }

    input {
      -webkit-appearance: searchfield;
    }

    &::-webkit-search-cancel-button {
      /* Remove default */
      -webkit-appearance: none;

      /* Now your own custom styles */
      height: 14px;
      width: 14px;
      display: block;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
      /* setup all the background tweaks for our custom icon */
      background-repeat: no-repeat;

      /* icon size */
      background-size: 14px;
    }
  }
`;

export function GlobalSearch() {
  const dispatch = useDispatch();
  const isNavPinned = useSelector((state) => state.ui.navPinned);
  const isAtTop = useSelector((state) => state.ui.scrolledToTop);
  const ref = useRef<HTMLInputElement>(null);

  return (
    <label htmlFor="search">
      <Box direction="row" align="center" gap="small">
        <IconSearch
          onClick={() => {
            if (ref.current) {
              ref.current.focus();
            }
          }}
          color="black"
          size="1rem"
        />
        <SearchInput
          ref={ref}
          size="small"
          name="search"
          placeholder="Search"
          aria-label="Search"
          type="search"
          disabled={!isNavPinned && !isAtTop}
          onFocus={(e) => {
            dispatch(setGlobalSearchQuery(e.target.value));
          }}
          onChange={(e) => {
            if (e.target.value.length > 2) {
              dispatch(setGlobalSearchQuery(e.target.value));
            } else {
              dispatch(setGlobalSearchQuery(''));
            }
          }}
        />
      </Box>
    </label>
  );
}
