import React, { useContext, useEffect, useRef } from 'react';
import { Box, Paragraph, Text, ThemeContext } from 'grommet';
import styled from 'styled-components';
import { m, LazyMotion } from 'framer-motion';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { use100vh } from 'react-div-100vh';

import { useGlobalSearhResults } from '../useGlobalSearchResults';
import SmartLink from '../SmartLink';
import { getProductPath } from '../../lib/product';
import ImgixImage from '../ImgixImage';
import ResponsiveGrid from '../ResponsiveGrid';
import TextTitle from '../TextTitle';
import useMenuOffset from '../useMenuOffset';

const loadFeatures = () =>
  import('../../lib/framer/motionFeatures.js').then((res) => res.default);

const MotionBox = m(Box);

const SearchResultGridItem = styled(Box)`
  min-height: calc(
    100vw / 4 - ${(p) => p.theme.global.edgeSize.small} -
      ${(p) => p.theme.global.edgeSize.small}
  );

  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (max-width: ${p.theme.global.breakpoints.small.value}px)`} {
    min-height: calc(
      100vw / 2 - ${(p) => p.theme.global.edgeSize.small} -
        ${(p) => p.theme.global.edgeSize.small}
    );
  }
`;

const variants = {
  active: {
    zIndex: 150,
    position: 'fixed',
    opacity: 1,
    pointerEvents: 'auto',
    transition: {
      type: 'tween',
      ease: 'circOut',
      duration: 0.55,
    },
  },
  inactive: {
    zIndex: 150,
    position: 'fixed',
    opacity: 0,
    pointerEvents: 'none',
    transition: {
      type: 'tween',
      ease: 'easeOut',
      duration: 0.15,
    },
  },
};

export function GlobalSearchResults() {
  const { results, searchQuery } = useGlobalSearhResults();
  const theme = useContext(ThemeContext);
  const { navHeight } = useMenuOffset();
  const showResults = searchQuery.length > 2;
  const scrollContainer = useRef(null);
  const viewportHeight = use100vh();

  useEffect(() => {
    if (showResults) {
      disableBodyScroll(scrollContainer.current);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [showResults]);

  const imageSizes = `(min-width: ${
    theme.global.breakpoints.medium.value
  }px) calc(${100 / 3}vw - ${theme.global.edgeSize.large} - ${
    theme.global.edgeSize.large
  }), (min-width: ${theme.global.breakpoints.medsmall.value}px) calc(33vw - ${
    theme.global.edgeSize.large
  } - ${theme.global.edgeSize.large}),
    (min-width: ${theme.global.breakpoints.small.value}px) calc(50vw - ${
    theme.global.edgeSize.large
  } - ${theme.global.edgeSize.large}), ${viewportHeight}px`;

  return (
    <LazyMotion strict features={loadFeatures}>
      <MotionBox
        variants={variants}
        margin={{ top: `${navHeight}px` }}
        height={`calc(${viewportHeight}px - ${navHeight}px)`}
        width="100%"
        background="white"
        animate={showResults ? 'active' : 'inactive'}
        initial="inactive"
      >
        <Box ref={scrollContainer} overflow="auto" fill>
          <div>
            <Box pad="xlarge">
              <Box>
                <Paragraph>
                  {results.length} results for{' '}
                  <Text weight={500}>{searchQuery}</Text>
                </Paragraph>
              </Box>
              <ResponsiveGrid
                disableMobile={true}
                gap={'large'}
                fill="horizontal"
                columns={{ count: 4, size: 'auto' }}
                mobileColumns={{ count: 2, size: 'auto' }}
              >
                {results.map((result) => (
                  <SearchResultGridItem key={result.item.id} fill>
                    <Box flex={{ grow: 1, shrink: 0 }}>
                      <SmartLink to={getProductPath(result.item)}>
                        <ImgixImage
                          srcSetOptions={{
                            fit: 'crop',
                            auto: ['format', 'compress'],
                            ar: '1:1',
                            q: 80,
                            crop: 'entropy',
                          }}
                          sizes={imageSizes}
                          src={result.item.imageUrl}
                          fit="contain"
                          fill={true}
                          alt={result.item.title}
                          pixelSwap={true}
                        />
                      </SmartLink>
                    </Box>
                    <Box flex={{ grow: 0, shrink: 0 }}>
                      <SmartLink plain to={getProductPath(result.item)}>
                        <TextTitle>{result.item.title}</TextTitle>
                      </SmartLink>
                    </Box>
                  </SearchResultGridItem>
                ))}
              </ResponsiveGrid>
            </Box>
          </div>
        </Box>
      </MotionBox>
    </LazyMotion>
  );
}
